/* You can add global styles to this file, and also import other style files */

@use 'src/theme/partials/spaces';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: var(--primary-text);
}

.grid-1-column {
    display: grid;
    grid-template-columns: 1fr;
}

.grid-2-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.grid-3-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.form-flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mat-icon-filled {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    min-height: 250px;
}

.smaller-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    min-height: 150px;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control:not(
        :focus
    )::placeholder {
    color: transparent;
}

.mat-toolbar {
    background-color: var(--white) !important;
}

.mat-mdc-card {
    border: 1px solid var(--border-gray) !important;
}

.mat-mdc-raised-button.mat-accent {
    color: white !important;
}

.aligned-with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    &.scale-icon-down {
        .mat-icon {
            transform: scale(0.7);
        }
    }
}

.aligned-with-icon-left {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
    &.scale-icon-down {
        .mat-icon {
            transform: scale(0.7);
        }
    }
}

.detail-title {
    margin: 0 !important;
    span {
        display: flex;
        align-items: center;
        gap: 0.5em;
    }
}

// Cookie consent border
.cc-window,
.cc-revoke {
    border: 1px solid var(--border-gray);
}

// Intro.js (on boarding library)
.introjs-tooltip {
    min-width: 300px !important;
    max-width: 350px !important;
}

.introjs-tooltiptext {
    color: black !important;
}

.introjs-prevbutton {
    // color: white !important;
    border: none !important;
    text-shadow: none !important;

    background-color: white !important;
    box-shadow:
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.introjs-nextbutton {
    background-color: var(--accent) !important;
    color: white !important;
    border: none !important;
    text-shadow: none !important;
}

.introjs-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
    box-shadow:
        0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
